import React from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap';
import {Link} from 'gatsby';

import CardAppPlatform from '../components/card-app-platform';
import CardAppPeCan from '../components/card-app-pecan';
import CardAppVizComm from '../components/card-app-vizcomm';
import CardDomain from '../components/card-domain';
import FacebookPixel from '../components/pixels/onLoad/FacebookPixel';
import Layout from '../components/layout';
import NewsCarousel from '../components/news-carousel';
import Section from '../components/section';
import SectionSubscribe from '../components/section-subscribe';
import SEO from '../components/seo';

import SJCLogo from '../images/STJCloud_Logo_White_NoTagline.png';
import StudiesIconNavy from '../images/icon_studies-navy.png';
import SJOLogo from '../images/sjcrh-logo-red.png';
import MicrosoftLogo from '../images/microsoft-logo.png';
import DNAnexusLogo from '../images/dnanexus-logo.png';
import CardAppModelSystems from '../components/card-app-model-systems';

const IndexPage = () => (
  <Layout className="homepage">
    <SEO title="St. Jude Cloud" titleTemplate="%s" />
    <FacebookPixel id={2561081110776824} />

    <Container className="homepage__hero" fluid>
      <Row>
        <Col className="text-center">
          <img src={SJCLogo} alt="St. Jude Cloud logo" />
          <h5>Advancing Cures Through Data and Discovery</h5>
        </Col>
      </Row>
    </Container>

    <Container className="card-domain__container">
      <Row className="card-domain__row">
        <CardDomain
          domainTitle="Pediatric Cancer"
          description="Data from a variety of childhood cancers, including hematological cancers, brain tumors and solid tumors."
          color="pediatric-cancer"
          url="/research-domains/pediatric-cancer"
        />
        <CardDomain
          domainTitle="Cancer Survivorship"
          description="High-quality genomic, clinical and patient-reported data from pediatric cancer survivors."
          color="cancer-survivorship"
          url="/research-domains/cancer-survivorship"
        />
        <CardDomain
          domainTitle="Non-Cancerous Diseases"
          description="Genomic and clinical data from non-malignant diseases, including sickle cell disease."
          color="non-cancerous-diseases"
          url="/research-domains/non-cancerous-diseases"
        />
      </Row>
    </Container>

    <Container className="about-sjc">
      <Row>
        <Col>
          St. Jude Cloud, an initiative of St. Jude Children&apos;s Research Hospital, provides data
          and analysis resources to the global research community. Our goal is to empower
          researchers across the world to advance cures for pediatric cancer and other pediatric
          catastrophic diseases. Partnering with Microsoft and DNAnexus, our apps are a cohesive
          blend of comprehensive data, scientific expertise, engineering innovation, and cloud
          infrastructure. Explore a research domain above to get started.
        </Col>
      </Row>
    </Container>

    <Section gray>
      <NewsCarousel />
    </Section>

    <Section className="app-cards__row">
      <Row>
        <Col>
          <h2>Apps</h2>
          <p className="mb-4">
            St. Jude Cloud is comprised of multiple applications that allow you to interact with our
            data in different ways.
          </p>
        </Col>
      </Row>
      <Row>
        <CardAppPlatform lg={6} />
        <CardAppPeCan lg={6} />
        <CardAppVizComm lg={6} />
        <CardAppModelSystems lg={6} />
      </Row>
    </Section>

    <Section gray>
      <Row>
        <Col md={{size: 3, order: 2}} className="study-icon__container">
          <img src={StudiesIconNavy} alt="studies" className="constrain-img studies-icon" />
        </Col>
        <Col md={9}>
          <h2>Studies</h2>
          <p>
            Genomic samples from a number of studies have been sequenced and released on St. Jude
            Cloud, including the Pediatric Cancer Genome Project, Sickle Cell Genome Project and St.
            Jude LIFE. Learn about our studies, including information on population cohorts,
            participant demographics and how the data sets were generated.
          </p>
          <Button as={Link} to="/studies" className="mt-3">
            Explore Studies
          </Button>
        </Col>
      </Row>
    </Section>

    <Section>
      <Row>
        <Col md={12} lg={3}>
          <p>
            St. Jude Cloud is made possible through close partnerships with these organizations.
          </p>
        </Col>
        <Col sm={12} md={4} lg={3} className="center-img-container">
          <a href="https://stjude.org" target="_blank" rel="noopener noreferrer">
            <img
              src={SJOLogo}
              alt="St. Jude Children's Research Hospital logo"
              className="constrain-img logo-max-width"
            />
          </a>
        </Col>
        <Col sm={12} md={4} lg={3} className="center-img-container">
          <a href="https://microsoft.com" target="_blank" rel="noopener noreferrer">
            <img
              src={MicrosoftLogo}
              alt="Microsoft logo"
              className="constrain-img logo-max-width"
            />
          </a>
        </Col>
        <Col sm={12} md={4} lg={3} className="center-img-container">
          <a href="https://dnanexus.com" target="_blank" rel="noopener noreferrer">
            <img src={DNAnexusLogo} alt="DNAnexus logo" className="constrain-img logo-max-width" />
          </a>
        </Col>
      </Row>
    </Section>

    <SectionSubscribe gray />
  </Layout>
);

export default IndexPage;
